<template>
	<div>
		<div :class="workersOverlayerForFabActive"></div>
		<div class="main">
			<div class="filterWrapper">	
                <v-text-field
				v-model="search"
                class="mr-5"
                outlined
                dense
                label="Search"
                append-icon="mdi-magnify"
                style="border-radius: 20px; height: 40px; width: 60%;"
				@keyup.enter="searchRecords"
				@change="updatePageData()"
				></v-text-field>

				<v-select class="ml-5"
				v-model="selectedProject"
				:items="dropdownProjects"
				item-text="name"
				item-value="id"
				label="Project"
				@change="updatePageData()"
				></v-select>

                <v-select class="ml-5" 
				v-model="selectedStatus"
				:items="dropdownStatus"
				item-text="name"
				item-value="value"
				label="Status"
				style="width: 20%;"
				@change="updatePageData()"
				></v-select>

				<v-btn
					class="mx-1"
					width="60px"
					text
					@click="openExportDialog"
				>
					<v-icon large color="primary">mdi-tray-arrow-down</v-icon>
				</v-btn>
				
				<!-- Visible column settings dialog -->
				<v-dialog v-model="dialog" max-width="400">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						color="primary" 
						fab
						small 
						v-bind="attrs" 
						v-on="on">
							<v-icon color="white">mdi-cog</v-icon>
						</v-btn>
					</template>
					<v-card class="px-5 py-2" >
						<v-card-title>
							Column management
						</v-card-title>

						<v-container
						class=""
						fluid
						>
							<v-checkbox 
							v-for="item in tableFields"
							:key="item.title"
							v-model="item.visibleChanged"
							:label="item.title"
							style="height: 30px"
							dense
							></v-checkbox>
						</v-container>

						<div style="display: flex; justify-content: flex-end;">
							<v-btn
							color="grey"
							class="mr-0 mt-0"
							text
							@click="dialog=false, resetColumnChanges()"
							>
							CANCEL
							</v-btn>
							<v-btn
							color="primary"
							class="mr-0 mt-0"
							text
							@click="dialog=false, saveColumnChanges()"
							>
							SAVE
							</v-btn>
						</div>
					</v-card>
				</v-dialog>
			</div>

            <!-- List - desktop view -->
            <div class='records-desktop-container'>
                <v-list>
						<div class="tableRow" v-bind:style="{'grid-template-columns': tableRowGridColumns}">
							<v-list-item 
							class="tableHeader font-weight-medium" 
							>
								<v-list-item-content>
									<v-checkbox class="ma-0 pa-0"
										style="height: 25px"
										v-model="mainCheckbox"
										:indeterminate="mainCheckboxIndeterminate"
										@click="()=>{switchCheckAllWorkers()}"
									></v-checkbox>
								</v-list-item-content>
							</v-list-item>
							<v-list-item 
							class="tableHeader font-weight-medium" 
							v-for="item in visibleColumns" 
							:key="item.title" 
							@click="sortBy(item.title)"
							>
								<v-list-item-content>
									<v-list-item-title>
										{{item.title}}
										<v-icon v-if="item.sortType=='asc'" >mdi-arrow-up-thin</v-icon>
										<v-icon v-if="item.sortType=='desc'" >mdi-arrow-down-thin</v-icon>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item class="tableHeader">
							</v-list-item>
							
							<v-list-item class="tableHeader">
							</v-list-item>
						</div>

						<p style="text-align: center;" class="font-italic grey--text pt-5" v-if="workersData.length==0">No data</p>

						<div v-for="item in workersData" :key="item.workerId">
							<div class="tableRow" v-bind:style="{'grid-template-columns': tableRowGridColumns}" style="border-bottom: 1px solid #e0e0e0;">
								<v-list-item-content>
									<v-checkbox class="mt-0 ml-4 pa-0"
										style="height: 25px"
										v-model="item.checked"
										@click="()=>{updateMainCheckbox()}"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item 
								v-for="column in visibleColumns" 
								:key="column.title" 
								v-on:mouseover="column.title=='Tags'? item.mouseoverTags=true: ''"
								v-on:mouseleave="column.title=='Tags'? item.mouseoverTags=false: ''">
									<v-list-item-content>
										<v-list-item-title v-if="column.title!='Projects' && column.title!='Comment' && column.title!='Tags'">{{item[column.itemProperty]}}</v-list-item-title>
										<div 
											v-else-if="column.title=='Tags'" 
											style="display: flex; flex-wrap: wrap;"
										>
											<v-chip 
												v-for="tag in item[column.itemProperty]" 
												:key="tag.id"
												:color="tag.color"
												class="mr-1 mb-1"
												style="max-width: 160px; overflow: hidden;"
												small
												close
												@click:close="deleteWorkerTag(item, tag)"
											><span style="overflow: hidden;">{{tag.name}}</span></v-chip>
										</div>
										<v-tooltip 
											v-else
											bottom
											max-width="600px"
										> 
											<template v-slot:activator="{ on, attrs }">
												<v-list-item-title 
													v-if="column.title=='Projects'"
													v-on="on"
													v-bind="attrs"
												>{{item[column.itemProperty]}}</v-list-item-title>
												<v-icon 
													v-if="column.title=='Comment' && item.comment"
													v-on="on"
													v-bind="attrs"
												>mdi-comment-text-outline</v-icon>
											</template>
											<span>{{item[column.itemProperty]}}</span>
										</v-tooltip>
									</v-list-item-content>

									<v-menu 
										v-if="column.title=='Tags'"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-list-item-action v-bind="attrs" v-on="on" class="ml-0">
												<!-- <v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }"> -->
														<v-icon 

															dense
														>mdi-pencil-box-multiple-outline</v-icon>
													<!-- </template>
													<span>Edit tags</span>
												</v-tooltip> -->
											</v-list-item-action>
										</template>
										<TagsMenu
											:worker="item"
											:availableTags="availableTags(item.tags)"
											@add-tag="addTag"
											@delete-worker-tag="deleteWorkerTag"
											@create-tag="createTag"
											@delete-tag="openConfirmRemovingTagDialog"
											@update-tag="updateTag"
										>
										</TagsMenu>
									</v-menu>
								</v-list-item>

								<v-list-item style="border-bottom: 1px solid #e0e0e0;">
								</v-list-item>

								<v-list-item style="border-bottom: 1px solid #e0e0e0;">
									<v-list-item-action>
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
											</template>

											<v-list dense class="pa-0">
												<v-list-item router-link :to="{name: 'workerPersonalData', params: {workerFullName: item.fullName, workerId: item.workerId}}">
													<v-list-item-content>
														<v-list-item-title>Personal data</v-list-item-title>
													</v-list-item-content>
												</v-list-item>

												<v-list-item v-if="permissions.includes('view_workerprojectassignment')" router-link :to="{name: 'workerProjects', params: {workerFullName: item.fullName, workerId: item.workerId}}">
													<v-list-item-content>
														<v-list-item-title>Projects</v-list-item-title>
													</v-list-item-content>
												</v-list-item>

												<v-list-item v-if="permissions.includes('view_card')" router-link :to="{name: 'cards', params: {workerFullName: item.fullName, workerId: item.workerId}}">
													<v-list-item-content>
														<v-list-item-title>Cards</v-list-item-title>
													</v-list-item-content>
												</v-list-item>

												<v-divider></v-divider>

												<v-list-item v-if="permissions.includes('workers_change')" @click="item.status=='Inactive'? changeWorkerStatus(item): openConfirmDeactivateDialog(item)">
													<v-list-item-content>
														<v-list-item-title v-if="item.status=='Inactive'">Activate</v-list-item-title>
														<v-list-item-title v-else>Deactivate</v-list-item-title>
													</v-list-item-content>
												</v-list-item>

												<v-list-item v-if="permissions.includes('workers_delete')" @click="item.deletable? openRemoveDialog(item): openDeactivateDialog(item)">
													<v-list-item-content>
														<v-list-item-title class="red--text">Delete</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-list-item-action>
								</v-list-item>
							</div>
						</div>
                </v-list>
            </div>

			<v-container class="mt-10 tableFooter">
				<div>
					Selected: {{checkedWorkers.length}}
				</div>
				<v-row 
				align="center"
				justify="end"
				class="pl-3 pr-16"
				>
				<span class="mr-5">Elementów na stronę:</span>
				<div class="selectWrapper">
					<v-select
					v-model="itemsPerPage"
					:items="itemsPerPageArray"
					dense
					@change="updatePageData()"
					></v-select>
				</div>

				<span
					class="mr-4"
				>
					{{firstItem}} - {{lastItem}} z {{totalItems}} pozycji
				</span>
				
				<v-icon @click="formerPage">mdi-chevron-left</v-icon>
				<v-icon @click="nextPage" class="mr-5">mdi-chevron-right</v-icon>
				</v-row>
			</v-container>
		</div>

		<!-- Add worker buttons -->
		<v-speed-dial
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            style="position: fixed; bottom: 16px; right: 16px;"
            :class="speedDialClass"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="primary"
                    fab
                    v-if="permissions.includes('workers_add')"
                >
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-plus 
                    </v-icon>
                </v-btn>
            </template>

            <v-tooltip left color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" @click="redirectToImportWorkersView"  fab small> 
                        <v-icon> mdi-briefcase-upload </v-icon> 
                    </v-btn>
                </template>
                <span style="color: black;"> Import workers </span>
            </v-tooltip>

            <v-tooltip left color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" @click="openAddWorkerDialog" fab small> 
                        <v-icon> mdi-plus </v-icon> 
                    </v-btn>
                </template>
                <span style="color: black;"> Add new worker </span>
            </v-tooltip>
        </v-speed-dial>

		<!-- DIALOGS -->

		<ExportWorkersDialog ref="exportWorkersDialog"
			:checkedWorkersNumber="checkedWorkers.length"
			:allWorkersNumber="workersData.length"
			@export-data="exportWorkers"
		></ExportWorkersDialog>

		<AddWorker ref="addWorkerDialog"
			v-if="permissions.includes('workers_add')"
			@refresh-data="workerSaved($event)"
			@action-result="actionResult"
			@show-overlayer="showOverlayer"
			@hide-overlayer="hideOverlayer"
			@set-state="setState"
		></AddWorker>

		<v-snackbar
             v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
		</v-snackbar>

		<!-- Confirm removing worker dialog -->
		<v-dialog 
        v-model="removeDialog.visible"
		width="450"
        persistent>
            <v-card>
                <v-card-title>
					<span v-if="removeDialog.item.itemsAssigned">Are you sure you want to delete worker <br> {{removeDialog.item.fullName}}?</span>
					<span v-else>Deleting worker</span>
				</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text mt-0 pt-0 pb-3">
					<p v-if="!removeDialog.item.itemsAssigned" class="font-weight-regular black--text mt-6">Are you sure you want to remove worker {{removeDialog.item.fullName}}?</p>
					<p v-if="removeDialog.item.itemsAssigned" class="font-weight-regular black--text mt-6 mb-2">Worker has assigned items:</p>
					<ul v-if="removeDialog.item.itemsAssigned">
						<li v-if="removeDialog.item.cards>0">Cards: {{removeDialog.item.cards}}</li>
						<li v-if="removeDialog.item.projects>0">Projects:  {{removeDialog.item.projects}}</li>
						<li v-if="removeDialog.item.shifts>0">Shifts:  {{removeDialog.item.shifts}}</li>
					</ul>
				</v-card-text>
				<v-divider v-if="removeDialog.item.itemsAssigned"></v-divider>
				<v-card-actions class="actionButtonsWrapper">
					<v-btn
					color="grey"
					class="mr-0 mt-0"
					text
					@click="closeRemoveDialog()"
					>CANCEL</v-btn>
					<v-btn
					color="error"
					class="mr-0 mt-0"
					text
					@click="confirmRemoveWorker(removeDialog.item)"
					>DELETE</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>

		<!-- Deactivate worker dialog (when removing is disabled) -->
		<v-dialog 
        v-model="deactivateDialog.visible"
		max-width="500"
        persistent>
            <v-card>
                <v-card-title class="red--text">Worker {{deactivateDialog.item.fullName}} cannot be deleted</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text pb-5">
					<p class="font-weight-regular black--text mt-6 mb-2">Worker has assigned items:</p>
					<ul>
						<li v-if="deactivateDialog.item.scans>0">Card scans: {{deactivateDialog.item.scans}}</li>
						<li v-if="deactivateDialog.item.leaves>0">Leaves:  {{deactivateDialog.item.leaves}}</li>
					</ul>
					<p v-if="deactivateDialog.item.status=='Active'" class="font-weight-regular black--text mt-6">Worker can only be deactivated.</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="actionButtonsWrapper">
					<v-btn
					color="grey"
					class="mr-0 mt-0"
					text
					@click="closeDeactivateDialog()"
					>CANCEL</v-btn>
					<v-btn
					v-if="deactivateDialog.item.status=='Active'"
					color="error"
					class="ml-3 mr-0 mt-0"
					text
					@click="confirmDeactivateWorker(deactivateDialog.item)"
					>DEACTIVATE</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>

		<!-- Confirm deactivate worker dialog -->
		<v-dialog 
        v-model="confirmDeactivateDialog.visible"
		width="300"
        persistent>
            <v-card>
                <v-card-title class="red--text">Worker deactivation</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text pb-2">
					<p class="font-weight-regular black--text mt-6">Are you sure you want to deactivate worker <b>{{confirmDeactivateDialog.item.fullName}}</b>?</p>
					<div class="actionButtonsWrapper">
						<v-btn
						color="grey"
						class="mr-0 mt-0"
						text
						@click="closeConfirmDeactivateDialog()"
						>CANCEL</v-btn>
						<v-btn
						color="error"
						class="mr-0 mt-0"
						text
						@click="confirmDeactivateWorker(confirmDeactivateDialog.item)"
						>CONFIRM</v-btn>
					</div>
				</v-card-text>
            </v-card>
        </v-dialog>

		<!-- Confirm removing tag -->
		<v-dialog 
        v-model="confirmRemovingTagDialog.visible"
		width="300"
        persistent>
            <v-card>
                <v-card-title>Deleting tag</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text pb-2">
					<p class="font-weight-regular black--text mt-6">Are you sure you want to delete tag <b>{{confirmRemovingTagDialog.item.name}}</b>?</p>
					<div class="actionButtonsWrapper">
						<v-btn
						color="grey"
						class="mr-0 mt-0"
						text
						@click="closeConfirmRemovingTagDialog()"
						>CANCEL</v-btn>
						<v-btn
						color="error"
						class="mr-0 mt-0"
						text
						@click="confirmRemovingTag(confirmRemovingTagDialog.item)"
						>CONFIRM</v-btn>
					</div>
				</v-card-text>
            </v-card>
        </v-dialog>


		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="errorDialog.model = false"
					>OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
	

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
import TagsMenu from './TagsMenu.vue'
import ExportWorkersDialog from './ExportWorkersDialog.vue'
//import AddWorker from './AddWorker.vue'
const AddWorker = () => import('./AddWorker.vue');

export default {
	name: 'Workers',

	components: {
		AddWorker,
		TagsMenu,
		ExportWorkersDialog,
	},

	data:() => ({
		permissions: null,
		title: 'Workers',
		modal: false,
		dialog: false,

		// v-speed-dial data
        direction: 'top',
        fab: false,
        fling: false,
        hover: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition',

		removeDialog:{
			visible: false,
			item: {}
		},
		deactivateDialog:{
			visible: false,
			item: {}
		},
		confirmDeactivateDialog:{
			visible: false,
			item: {}
		},
		confirmRemovingTagDialog:{
			visible:false,
			item: {}
		},
		search: "",
		page: 1,
		itemsPerPage: 10,
		itemsPerPageArray: [5, 10, 50, 100],
        totalItems: 0,
		selectedProject: 0,
		dropdownProjects: ["All"],
        selectedStatus: "All",
        dropdownStatus: [
			{
				name: "All",
				value: "All"
			},
			{
				name: "Active",
				value: 0
			},
			{ 
				name: "Inactive",
				value: 1
			}
		],
        tableFields: [
			{
				id: 1,
				title: "Fullname",
				itemProperty: "fullName",
				sort: true,
				sortField: "last_name",
				sortType: "asc",
				visible: true,
				visibleChanged: true,
				width: "230px"
			},
			{
				id: 2,
				title: "Tags",
				itemProperty: "tags",
				sort: false,
				sortField: "",
				sortType: "",
				visible: true,
				visibleChanged: true,
				width: "220px"
			},
			{
				id: 3,
				title: "Birth date", 
				itemProperty: "birthDate",
				sort: false,
				sortField: "birth_date",
				sortType: "asc",
				visible: true,
				visibleChanged: true,
				width: "150px" 
			},
			{
				id: 4,
				title: "Projects", 
				itemProperty: "projectsLabel",
				sort: false,
				sortField: "name",
				sortType: "asc",
				visible: true,
				visibleChanged: true,
				width: "230px"
			},
			{
				id: 5,
				title: "Status",
				itemProperty: "status",
				sort: false,
				sortField: "auth_user",
				sortType: "asc",
				visible: true ,
				visibleChanged: true,
				width: "120px"
			},
			{
				id: 6,
				title: "Phone number",
				itemProperty: "phoneNumber",
				sort: false,
				sortField: "phone_number",
				sortType: "asc",
				visible: true,
				visibleChanged: true,
				width: "170px"
			},
			{
				id: 7,
				title: "Email",
				itemProperty: "email",
				sort: false,
				sortField: "email",
				sortType: "asc",
				visible: false,
				visibleChanged: false,
				width: "250px" 
			},
			{
				id: 8,
				title: "Cards",
				itemProperty: "cards",
				sort: false,
				sortField: "",
				sortType: "",
				visible: true,
				visibleChanged: true,
				width: "80px"
			},
			{
				id: 9,
				title: "Comment",
				itemProperty: "comment",
				sort: false,
				sortField: "",
				sortType: "",
				visible: true,
				visibleChanged: true,
				width: "120px"
			},
		],

		mainCheckbox: false,
		mainCheckboxIndeterminate: false,

		workersData: [],
		tagsList: [],
		errorDialog: {
			errorTitle: "Error occured",
			errorMessage: "An error occured",
			model: false
		},
		snackbar: {
            visible: false,
            color: "primary",
            message: ""
        },
	}),
	beforeMount(){
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}
	},
	mounted: async function() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		if (!this.permissions.includes("workers_view")) {
            this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
            return
		}
		this.selectedProject=this.$store.state.workersCache.selectedProject
		this.search=this.$store.state.workersCache.search
		this.selectedStatus = this.$store.state.workersCache.status
		this.itemsPerPage=this.$store.state.workersCache.itemsPerPage
		this.page=this.$store.state.workersCache.page
		let columnsVisible = this.$store.state.workersCache.columnsVisible
		for(let item of this.tableFields){
			item.visible=columnsVisible[item.id]
			item.visibleChanged=columnsVisible[item.id]
		}
		await this.getTagsFromApi()
		this.getProjectsFromApi()
		this.getDataFromApi()
		this.setTitle();
		this.getGoBackLink();
	},
	computed: {
		firstItem(){
			return this.totalItems? (this.page-1)*this.itemsPerPage+1: 0
		},
        lastItem(){
			if(this.totalItems){
				return this.totalItems< this.page*this.itemsPerPage? this.totalItems: this.page*this.itemsPerPage
			}
			return 0
        },
		checkedWorkers(){
			let result=[]
			if(this.workersData){
				for(let item of this.workersData){
					if(item.checked){
						result.push(item)
					}
				}
			}
			return result
		},
        dropdownWorkers(){
            return {}
        },
		visibleColumns(){
			let visible=[]
			if(this.tableFields){
				for(let item of this.tableFields){
					if(item.visible){
						visible.push(item)
					}
				}
			}
			return visible
		},
		tableRowGridColumns(){
			let style="60px "
			if(this.visibleColumns){
				for(let item of this.visibleColumns){
					style+=item.width
					style+=" "
				}
				style+="1fr "
				style+="50px"
			}
			return style
		},
		workersOverlayerForFabActive: {
            get: function() { 
                if(this.fab){
                    return "workers-overlayer-for-fab overlayer-for-fab-active";
                }
                else{
                    return "workers-overlayer-for-fab";
                }
            }
        },
		speedDialClass: {
            get: function(){
                if(this.fab){
                    return "workers-speed-dial speed-dial-active";
                }
                else {
                    return "workers-speed-dial";
                }
            }
        }	
	},
	methods: {
		setTitle() {
			this.$emit('set-title', this.title);
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "");
		},
		sortBy(title){
			if(title){
				for(let item of this.tableFields){
					if(item.title==title){
						item.sort=true
						if(item.sortType=="asc"){
							item.sortType="desc"
						}
						else if(item.sortType=="desc"){
							item.sortType="asc"
						}
					}
					else{
						item.sort=false
					}
				}
			}
			this.getDataFromApi()
		},
		getSortType(){
			let sortParametr=""
			for(let item of this.tableFields){
				if(item.sort==true){
					if(item.title=="Projects"){
						sortParametr+="projectassignments__project__"
					}
					else{
						sortParametr+="personal_data__"
					}
					sortParametr+=item.sortField
					sortParametr+="."
					sortParametr+=item.sortType
					break
				}
			}
			return sortParametr
		},
		resetColumnChanges(){
			if(this.tableFields){
				for(let item of this.tableFields){
					item.visibleChanged=item.visible
				}
			}
		},
		saveColumnChanges(){
			if(this.tableFields){
				for(let item of this.tableFields){
					item.visible=item.visibleChanged
				}
			}
			this.updateWorkersCacheStore()
		},
		getDataFromApi(){
			let params = {
				'page': this.page,
				'per_page': this.itemsPerPage,
				'order_by': this.getSortType()
			}
			if(this.selectedProject!=0){
				params['projects']= this.selectedProject
			}
			if(this.search!=""){
				params['personal_data_search']=this.search
			}
			if(this.selectedStatus!="All"){
				params['status']=this.selectedStatus
			}	
						
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: params
			}).then((response) => {
				this.totalItems=response.data.total
				this.adaptApiResponseWorkers(response.data.items)
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getProjectsFromApi(){
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: {
					type: 0
				}
			}).then((response) => {
				this.adaptApiResponseProjects(response.data)
			})
			.catch((error) => {
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getTagsFromApi: async function(){
			await axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/tags",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
			}).then((response) => {
				this.adaptApiResponseTags(response.data)
			})
			.catch((error) => {
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		removeWorker(item){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing worker...");
			axios({
				method:'delete',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+item.workerId,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
			}).then(() => {
				this.$emit("set-state", "DEFAULT");
				this.snackbar.visible=true
				this.snackbar.color='success'
				this.snackbar.message="Worker deleted succesfully"
				this.getDataFromApi()
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		changeWorkerStatus(item){
			if(item.status=="Active"){
				this.$emit("set-state", "LOADING_OVERLAYER", "Deactivating worker...");
			}
			else{
				this.$emit("set-state", "LOADING_OVERLAYER", "Activating worker...");
			}
			axios({
				method:'patch',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+item.workerId,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					status: item.status=="Active"? 1: 0,
				}
			}).then(() => {
				this.$emit("set-state", "DEFAULT");
				this.snackbar.visible=true
				this.snackbar.color='success'
				this.snackbar.message="Worker status updated succesfully"
				this.getDataFromApi()
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		updateWorkerTags(item, newTags){
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'patch',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+item.workerId,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					tags: newTags
				}
			}).then((response) => {
				item.tags=this.getDisplayTags(response.data.tags)
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		createNewTag: async function(worker,name){
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'post',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/tags/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					"choice_name": name,
					"disabled": false,
					"color": "#E0E0E0"
				}
			}).then((response) => {
				this.getTagsFromApi()
				this.addTag(worker, {
					id: response.data.id,
					name: response.data.choice_name,
					color: response.data.color
				})
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		confirmDeleteTag: async function(tag){
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'delete',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/tags/"+tag.id,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
			}).then(() => {
				this.getTagsFromApi()
				this.getDataFromApi()
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}
				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						if(error.response.data.tag && error.response.data.tag[0].code=="TAG_ASSIGNED"){
							this.errorDialog.errorTitle = "Cannot delete tag";
							this.errorDialog.errorMessage = "Tag is assigned to "+ error.response.data.tag[1].count+" workers.";
							this.errorDialog.model = true;
							return
						}
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		updateTag: function(tag){
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'patch',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/tags/"+tag.id,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					choice_name: tag.name,
					color: tag.color,
				}
			}).then(() => {
				this.$emit("set-state", "DEFAULT");
				this.getTagsFromApi()
				this.getDataFromApi()
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.visible=true
						this.snackbar.color='error'
						this.snackbar.message="You do not have priviledges to this action"
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		adaptApiResponseWorkers(items){
			let workersData =[]
			if(items){
				for(let item of items){
					let workerData={
						checked: false,
						workerId: item.id,
						fullName: item.personal_data.last_name+" "+item.personal_data.first_name,
						birthDate: this.dateString(item.personal_data.birth_date),
						email: item.personal_data.email,
						phoneNumber: item.personal_data.phone_number,
						status: "",
						comment: item.personal_data.comment,
						cards: item.cards,
						scans: item.scans,
						leaves: item.leaves,
						shifts: item.shifts,
						projects: item.projects.length,
						projectsLabel: "",
						deletable: item.allow_delete_worker, //TODO
						tags: this.getDisplayTags(item.tags),
						mouseoverTags: false,
					}
					if(item.status==0){
						workerData.status= "Active"
					}
					else if(item.status==1){
						workerData.status= "Inactive"
					}
					if(item.projects.length>0){
						workerData.projectsLabel+=item.projects[0].name
					}
					for(let i=1;i<item.projects.length;i++){
						workerData.projectsLabel+=", "
						workerData.projectsLabel+=item.projects[i].name
					}
					workersData.push(workerData)
				}
			}
			this.workersData=workersData
			this.updateMainCheckbox()
		},
		adaptApiResponseProjects(items){
			let projects = [{
				id: 0,
				name: "All"
			}]
			if(items){
				for(let item of items){
					projects.push({
						id: item.id,
						name: item.name
					})
				}
			}
			this.dropdownProjects=projects
		},
		adaptApiResponseTags(items){
			let tags = []
			if(items){
				for(let item of items){
					tags.push({
						id: item.id,
						name: item.choice_name,
						color: item.color,
					})
				}
			}
			this.tagsList = tags
		},
		getDisplayTags(tagsIdx){
			let displayTags = []
			if(tagsIdx && this.tagsList){
				for(let item of tagsIdx){
					let tag = this.tagsList.find(i=> i.id==item)
					if(tag){
						displayTags.push(tag)
					}
				}
			}
			return displayTags
		},
		//convert date from format yyyy-mm-dd to dd.mm.yyyy
		dateString(date){
			if(date){
				let newDate =""
				newDate+=date.substr(8,2)
				newDate+="."
				newDate+=date.substr(5,2)
				newDate+="."
				newDate+=date.substr(0,4)
				return newDate
			}
			return "---"
		},

		//Export dialog methods
		openExportDialog(){
			this.$refs.exportWorkersDialog.openDialog()
		},

		exportWorkers(exportOptions){
			let workersIds=[]
			let arr= exportOptions.workersToExport=="all"? this.workersData: this.checkedWorkers;
			for(let item of arr){
				workersIds.push(item.workerId)
			}
			let params={
				date_from: exportOptions.dateFrom,
				date_to: exportOptions.dateTo,
				workers_id: workersIds.toString(),
				only_personal_data: exportOptions.onlyPersonalData,
				language: "en" //do zmiany
			}
			if(this.selectedProject!=0){
				params.projects=this.selectedProject
			}

			this.$emit("set-state",  "LOADING_OVERLAYER");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/excel/",
				responseType: 'blob',
				headers: {
					// "Content-Type": "xlsx",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: params
			}).then((response) => {
				const url = URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute(
				'download',
				`Workers_${exportOptions.dateFrom}-${exportOptions.dateTo}.xlsx`
				)
				document.body.appendChild(link)
				link.click()
				this.$emit("set-state",  "DEFAULT");
				this.$refs.exportWorkersDialog.closeDialog();
				this.$emit("display-success",  "Data exported succesfully");
			})
			.catch((error) => {
				console.log(error)
				this.$emit("set-state",  "DEFAULT");
				// this.handleError(error) TODO
			})

		},

		//Remove dialog methods
		openRemoveDialog(item){
			this.removeDialog.item=item
			if(item.cards>0 || item.shifts>0 || item.projects>0){
				this.removeDialog.item.itemsAssigned=true
			}
			this.removeDialog.visible=true
		},
		closeRemoveDialog(){
			this.removeDialog.visible=false
			this.removeDialog.item={}
		},
		confirmRemoveWorker(item){
			this.removeWorker(item)
			this.closeRemoveDialog()
		},

		//Deactivate dialog methods
		openDeactivateDialog(item){
			this.deactivateDialog.item=item
			this.deactivateDialog.visible=true
		},
		closeDeactivateDialog(){
			this.deactivateDialog.item={}
			this.deactivateDialog.visible=false
		},
		
		//Confirm deactivate dialog methods
		openConfirmDeactivateDialog(item){
			this.confirmDeactivateDialog.item=item
			this.confirmDeactivateDialog.visible=true
		},
		closeConfirmDeactivateDialog(){
			this.confirmDeactivateDialog.visible=false
			this.confirmDeactivateDialog.item={}
		},
		confirmDeactivateWorker(item){
			this.changeWorkerStatus(item)
			this.closeConfirmDeactivateDialog()
			this.closeDeactivateDialog()
		},

		//Confirm removing tag dialog methods
		openConfirmRemovingTagDialog(item){
			this.confirmRemovingTagDialog.item=item
			this.confirmRemovingTagDialog.visible=true
		},
		closeConfirmRemovingTagDialog(){
			this.confirmRemovingTagDialog.visible=false
			this.confirmRemovingTagDialog.item={}
		},
		confirmRemovingTag(item){
			this.closeConfirmRemovingTagDialog()
			this.confirmDeleteTag(item)
		},

		availableTags(workerTags){
			let result=[]
			for(let tag of this.tagsList){
				let idx = workerTags.findIndex(i => i.id==tag.id)
				if(idx==-1){
					result.push(tag)
				}
			}
			return result
		},

		addTag(worker,tag){
			let newTags = []
			for(let item of worker.tags){
				newTags.push(item.id)
			}
			newTags.push(tag.id)
			this.updateWorkerTags(worker, newTags)
		},

		deleteWorkerTag(worker, tag){
			let newTags = []
			for(let item of worker.tags){
				if(item.id!=tag.id){
					newTags.push(item.id)
				}
			}
			this.updateWorkerTags(worker, newTags)
		},
		createTag: async function(worker, name){
			await this.createNewTag(worker, name)
		},

		switchCheckAllWorkers(){
			for(let item of this.workersData){
				item.checked=this.mainCheckbox
				this.mainCheckboxIndeterminate=false
			}
		},
		updateMainCheckbox(){
			if(this.workersData){
				if(this.checkedWorkers.length==0){
					this.mainCheckbox=false
					this.mainCheckboxIndeterminate=false
				}
				else if(this.checkedWorkers.length==this.workersData.length){
					this.mainCheckbox=true
					this.mainCheckboxIndeterminate=false
				}
				else{
					this.mainCheckbox=false
					this.mainCheckboxIndeterminate=true
				}
			}
		},

		nextPage(){
			if(this.lastItem<this.totalItems){
				this.page++
				this.getDataFromApi()
			}
		},
		formerPage () {
			if(this.firstItem>1){
				this.page--
				this.getDataFromApi()
			}
		},
		searchRecords(){
			this.page=1
			this.getDataFromApi()
		},


		//Add Worker dialog methods
		openAddWorkerDialog(){
			this.$refs.addWorkerDialog.dialog=true
		},
		workerSaved(workerFullName){
			this.page=1
			this.getDataFromApi()
			this.snackbar.visible=true
			this.snackbar.color='success'
			this.snackbar.message = "Worker "+workerFullName+" added succesfully"
		},
		actionResult(result, message){
			this.snackbar.visible=true
			this.snackbar.color=result
			this.snackbar.message=message
		},
		showOverlayer: function(message) {
            this.$emit("set-state", "LOADING_OVERLAYER", message);
        },
        hideOverlayer: function() {
            this.$emit("set-state", "DEFAULT");
        },
		setState(newState, additionalParams = null){
			this.$emit('set-state', newState, additionalParams);
		},


		redirectToImportWorkersView: function(){
            this.$router.push("/import-workers");
        },
		updateWorkersCacheStore(){
			let data = {
				type: 'updateWorkersCache',
				page: this.page,
				itemsPerPage: this.itemsPerPage,
				search: this.search,
				project: this.selectedProject,
				status: this.selectedStatus,
				columnsVisible: {}
			}
			for(let item of this.tableFields){
				data.columnsVisible[item.id]=item.visible
			}
			this.$store.commit(data)
		},
		updatePageData(){
			this.page=1
			this.getDataFromApi()
			this.updateWorkersCacheStore()
		}
		
	},
	watch: {
		page: function(){
			this.updateWorkersCacheStore()
		},
		search: function(){
			if(this.search==""){
				this.page=1
				this.getDataFromApi()
				this.updateWorkersCacheStore()
			}
		},
	}
};
</script>

<style scoped>

.records-desktop-container {
	max-width: 100%;
	overflow-x: auto;
}

.v-text-field .v-input__control .v-input__slot {
	min-height: auto !important; 
}

.filterWrapper{
	padding: 10px;
	display: flex;
	width: 100%;
	align-items: center;
}

.v-select {
	width: 50%;
}



.tableHeader {
	background: #FBC02D;
	overflow: hidden;
}

.tableRow{
	display: grid;
}

/* @media screen and (min-width: 1180px) {
	.tableRow{
		grid-template-columns: 200px repeat(7, 120px) 82px;;
	}
}

@media only screen and (max-width: 960px) { 
	.tableRow {
		grid-template-columns: repeat(2, 1fr);
	}
} */

.v-list-item{
	min-height: 20px !important;
}

.tableFooter{
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
}

@media screen and (max-width: 600px) {
	.tableFooter{
		align-items: flex-start;
		flex-direction: column;
	}
}

.selectWrapper{
	padding-top: 10px;
	margin-right: 20px;
	width: 50px;
}

.selectWrapper .v-select {
	width: 100%;
}

.workers-overlayer-for-fab {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(33,33,33);
    left: 0;
    top: 0;
    z-index: 15;
    visibility: hidden;
    opacity: 0.0;

    transition: visibility 0.2s, opacity 0.2s linear;
}
.workers-overlayer-for-fab.overlayer-for-fab-active {
    visibility: visible;
    opacity: 0.46;
}

.workers-speed-dial{
    z-index: 2;
}
.workers-speed-dial.speed-dial-active {
    z-index: 20;
}
</style>
