<template>
	<div>	
		<v-list dense style="width: 200px;">
			<v-list-item v-if="worker.tags.length>0">
				<div style="display: flex; flex-wrap: wrap;">
					<v-chip 
						v-for="tag in worker.tags" 
						:key="tag.id"
						:color="tag.color"
						class="mr-1 mb-1"
						small
						close
						@click:close="deleteWorkerTag(tag)"
					>{{tag.name}}</v-chip>
				</div>
			</v-list-item>

			<v-divider v-if="worker.tags.length>0"></v-divider>

			<v-text-field
				v-model="search"
				label="Search or create new"
				class="mx-1 mb-2"
				counter
				maxlength="20"
				@keyup.enter="createTag()"
			></v-text-field>

			<p 
			v-if="search!=''" 
			style="font-size: 14px; font-style: italic; color: #757575; margin-left: 5px;"
			>{{message}}</p>
												
			<v-list-item
				v-for="tag in displayTags"
				:key="tag.id"
				class="my-1"
			>
				<v-list-item-title 
					:style="{'color': tag.color, 'cursor': 'pointer'}"
					@click="addTag(tag)"
				><b>{{ tag.name }}</b></v-list-item-title>

				<v-menu>
					<template v-slot:activator="{ on, attrs }">
						<v-list-item-action v-bind="attrs" v-on="on" class="ma-0">
							<v-icon dense>mdi-dots-vertical</v-icon>
						</v-list-item-action>
					</template>
					<v-list dense>
						<v-list-item class="pl-2 pr-3 pb-2" @click="deleteTag(tag)">
							<v-icon dense class="red--text mr-2">mdi-delete</v-icon>
							<v-list-item-title class="red--text">Delete</v-list-item-title>
						</v-list-item>
						<v-list-item class="pl-2 pr-3" @click="openEditDialog(tag)">
							<v-icon dense class="mr-2">mdi-pencil</v-icon>
							<v-list-item-title>Edit</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-list-item>
		</v-list>

		<!-- Edit tag dialog -->
		<v-dialog
		v-model="editDialog.visible" 
		max-width="290px"
		persistent>
			<v-card>
				<v-card-title>Edit tag {{editDialog.title}}</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-3">
					<v-text-field
						v-model = editDialog.item.name
						label="Name"
						counter
						:rules="editDialog.rules.name"
						maxlength="20"
					></v-text-field>

					<div style="display:flex; justify-content: space-between; align-items: center; margin: 10px 0 5px 0;">
						<span class="black--text">Color</span>

						<div class="colorFieldWrapper">
							<v-text-field readonly v-model="editDialog.item.color" hide-details class="ma-0 pa-0" solo>
								<template v-slot:append>
									<v-menu v-model="editDialog.colorPicker" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
										<template v-slot:activator="{ on }">
											<div :style="swatchStyle(editDialog.item.color)" v-on="on" />
										</template>
										<v-card>
											<v-card-text class="pa-0">
												<v-color-picker v-model="editDialog.item.color" flat />
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
							</v-text-field>
						</div>
					</div>
				</v-card-text>
				
				<v-card-actions style="display: flex; justify-content: flex-end;">
						<v-btn
						color="grey"
						class="mr-0 mt-0"
						text
						@click="closeEditDialog()"
						>CANCEL</v-btn>
						<v-btn
						color="primary"
						class="mr-0 mt-0"
						text
						:disabled="editDialog.errors.name"
						@click="saveTagChanges()"
						>SAVE</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

export default ({
    name: 'TagsMenu',
    data: function(){
        return {
            search: "",
			message: "",
			createNewEnabled: false,
			editDialog: {
				visible: false,
				title: "",
				item: {},
				colorPicker: false,
				rules: {
					name: [],
				},
				errors: {
					name: false,
				},
				errorMessages: {
					name: ""
				}
			}
        }
    },
    props: {
        worker: Object,
        availableTags: [],
    },
    computed: {
        displayTags(){
            if(!this.search || this.search==""){
                return this.availableTags
            }
            else{
                let newSearch = this.search.toLowerCase()
                return this.availableTags.filter(item => {
                    let text = item.name.toLowerCase()
                    return text.indexOf(newSearch) > -1
                })
            }
        },
    },
    mounted(){
		this.editDialog.rules.name = [
			v => ( this.editTagNameRule(v) ) || this.editDialog.errorMessages.name
		];
    },
	watch: {
        search(){
			if(this.search && this.search!=""){
				let newSearch=this.search.toLowerCase()
				if(this.worker.tags.findIndex(item => item.name.toLowerCase()==newSearch)!=-1){
					this.message="Tag already attached"
					this.createNewEnabled=false
				}
				else if(this.availableTags.findIndex(item => item.name.toLowerCase()==newSearch)!=-1){
					this.message=""
					this.createNewEnabled=false
				}
				else{
					this.message="Press Enter to create new tag"
					this.createNewEnabled=true
				}
			}
			else{
				this.message=""
				this.createNewEnabled=false
			}
        }
    },
    methods: {
        addTag(tag){
            this.$emit("add-tag", this.worker, tag);
        },
        deleteWorkerTag(tag){
            this.$emit("delete-worker-tag", this.worker, tag);
        },
		createTag(){
			if(this.createNewEnabled){
				this.$emit("create-tag", this.worker, this.search);
				this.search=""
			}
			else{
				let tag = this.availableTags.find(item => item.name.toLowerCase()==this.search.toLowerCase())
				if(tag){
					this.addTag(tag)
					this.search=""
				}
			}
		},
		deleteTag(tag){
			this.$emit("delete-tag", tag);
		},
		openEditDialog(tag){
			this.editDialog.title=tag.name
			this.editDialog.item={
				id: tag.id,
				name: tag.name,
				color: tag.color
			}
			this.editDialog.visible=true
		},
		closeEditDialog(){
			this.editDialog.title=""
			this.editDialog.visible=false
			this.editDialog.item={}
		},
		saveTagChanges(){
			this.$emit("update-tag", this.editDialog.item);
			this.closeEditDialog()
		},
		editTagNameRule: function(name) {
			if (name == null || name == '') {
				this.editDialog.errors.name = true;
				this.editDialog.errorMessages.name = "Required tag name";
				return false;
			}
			
			let allTags = this.worker.tags.concat(this.availableTags)
			let idx = allTags.findIndex(x => { return x.name.toLowerCase() == name.toLowerCase(); });
			if (idx != -1 && (allTags[idx].id != this.editDialog.item.id)) {
				this.editDialog.errors.name = true;
				this.editDialog.errorMessages.name = "Tag already exists";
				return false;
			}
			
			this.editDialog.errors.name = false;
			this.editDialog.errorMessages.name = "";
			return true;
		},
		swatchStyle(color) {
			return {
				backgroundColor: color,
				cursor: 'pointer',
				height: '40px',
				width: '50px',
				border: '1px solid #686868',
				borderRadius: this.editDialog.colorPicker ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out'
			}
		},
    }
})
</script>

<style scoped>

.colorFieldWrapper{
	width: 75%;
}

/* @media only screen and (max-width: 450px) {
	.colorFieldWrapper {
		width: 40%;
	}
} */


</style>
